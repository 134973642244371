<template>
  <div v-if="isRolesEmpty">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </div>
  <div v-else>
    <div v-if="isAdmin" class="d-flex">
      <router-link to="/property/create" class="btn btn-primary mb-2">Создать параметр</router-link>
    </div>
    <b-table
        hover
        responsive
        :items="getPropertyDataTable"
        ref="property_table"
        :fields="fields"
        :current-page="propertyList.currentPage"
        :per-page="propertyList.perPage"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Параметры отсутствуют</h6>
      </template>
      <template v-slot:cell(interaction)="row">
        <template>
          <b-button variant="primary"
                    :title="isAdmin ? 'Редактирование' : 'Просмотр'"
                    @click="goToDetailPage(row.item.key)">
            <b-icon-pencil v-if="isAdmin" font-scale="0.75"></b-icon-pencil>
            <b-icon-eye v-else font-scale="0.75"></b-icon-eye>
          </b-button>
          <b-button class="ml-1" variant="primary"
                    v-if="isAdmin"
                    title="Удалить"
                    @click="deleteProperty(row.item.key)">
            <b-icon-trash font-scale="0.75"></b-icon-trash>
          </b-button>
        </template>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="propertyList.perPage"
            class="text-light border-secondary bg-secondary">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center d-none d-lg-block mb-2">записей на страницу</span>
      <b-pagination
          v-model="propertyList.currentPage"
          class="my-0 ml-auto mb-2"
          :total-rows="propertyList.totalCount"
          :per-page="propertyList.perPage"
          aria-controls="template-table"
          @input="getPropertyDataTable">
      </b-pagination>
    </div>
  </div>
</template>
<script>
import propertyApi from '@/modules/server-params';
import {mapGetters} from "vuex";

export default {
  name: 'user-list',
  data() {
    return {
      userId: this.$route.params.id,
      fields: [
        {
          key: 'key', label: 'Ключ'
        },
        {
          key: 'value', label: 'Значение'
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      propertyList: {
        perPage: 10,
        currentPage: 1,
        totalCount: 0,
      }
    };
  },
  computed: {
    ...mapGetters([
      "isRolesEmpty",
      "isAdmin"
    ])
  },
  mounted() {
    document.title = this.$route.meta.title;
  },
  methods: {
    getPropertyDataTable(context) {
      return propertyApi.getParamsList(context.currentPage, context.perPage)
          .then(resp => {
            this.propertyList.totalCount = resp.data.totalCount;
            return resp.data.items;
          });
    },
    goToDetailPage(key) {
      this.$router.push(`/properties/${key}`);
    },
    deleteProperty(key) {
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить параметр?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              propertyApi.deleteParam(key)
                  .catch(error => {
                    let errorMessage;
                    if (error.isAxiosError) {
                      errorMessage = `Ошибка при удалении параметра (код ${error.response?.status})`;
                    } else {
                      errorMessage = 'Неизвестная ошибка при удалении параметра';
                    }
                    this.$bvToast.toast(errorMessage, {
                      title: 'Ошибка',
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: true
                    });
                  })
                  .finally(() => {
                    this.$refs.property_table.refresh();
                  });
            }
          });
    }
  }
}
</script>
